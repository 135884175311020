/** @module GlassesSvg */
import React, { PureComponent } from 'react';

class GlassesSvg extends PureComponent {
  /** @inheritdoc */
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="47"
        height="16"
        viewBox="0 0 47 16"
      >
        <defs>
          <linearGradient id="b" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor="#FFB669" />
            <stop offset="100%" stopColor="#FD7F38" />
          </linearGradient>
          <path
            id="a"
            d="M27.259 11.384L23.147 2.89l-4.112 8.494a6.58 6.58 0 0 1-5.923 3.713H4.927a4.7 4.7 0 0 1-4.7-4.7V3.245a2.82 2.82 0 0 1 2.82-2.82h40.2a2.82 2.82 0 0 1 2.82 2.82v7.152a4.7 4.7 0 0 1-4.7 4.7H33.18a6.58 6.58 0 0 1-5.922-3.713z"
          />
        </defs>
        <g fill="none" fillRule="evenodd" transform="translate(.567 .02)">
          <mask id="c" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <use fill="url(#b)" fillRule="nonzero" xlinkHref="#a" />
          <path
            fill="#0D0D0D"
            fillRule="nonzero"
            stroke="#010101"
            strokeWidth=".5"
            d="M14.859-8.767c.075.038.094.113.056.189L-.887 19.109c-.038.075-.113.094-.189.056-.075-.038-.094-.113-.056-.188L14.67-8.71c.038-.075.132-.094.189-.057z"
            mask="url(#c)"
          />
          <path
            fill="#0D0D0D"
            fillRule="nonzero"
            d="M8.742-2.928l-.284-.15a.653.653 0 0 0-.906.244L-7.72 23.93a.65.65 0 0 0 .245.904l.283.15a.653.653 0 0 0 .907-.244L8.987-2.024c.189-.301.076-.715-.245-.904z"
            mask="url(#c)"
          />
          <path
            fill="#0D0D0D"
            fillRule="nonzero"
            stroke="#010101"
            strokeWidth=".5"
            d="M16.161-6.375a.466.466 0 0 0-.642.17L.04 20.898a.464.464 0 0 0 .17.64c.226.132.509.057.641-.17L16.331-5.733c.132-.226.057-.528-.17-.64zM49.012-8.767c.075.038.094.113.056.189L33.266 19.109c-.038.075-.113.094-.189.056-.075-.038-.094-.113-.056-.188L48.823-8.71c.038-.075.113-.094.189-.057z"
            mask="url(#c)"
          />
          <path
            fill="#0D0D0D"
            fillRule="nonzero"
            stroke="#010101"
            strokeWidth=".94"
            d="M46.406-9.652l-.207-.113a.635.635 0 0 0-.85.226L30.019 17.32a.632.632 0 0 0 .226.848l.208.113c.302.17.68.056.85-.226L46.652-8.823c.15-.301.056-.678-.246-.829z"
            mask="url(#c)"
          />
        </g>
      </svg>
    );
  }
}

export default GlassesSvg;
