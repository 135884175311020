/** @module RightArrowSvg */
import React, { PureComponent } from 'react';

class RightArrowSvg extends PureComponent {
  /** @inheritdoc */
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="16"
        height="10"
        viewBox="0 0 16 10"
        className="tio--right-arrow"
      >
        <path fill="#FFF" fillRule="evenodd" d="M10 0l6 4.94L10 10V6H0V4h10z" />
      </svg>
    );
  }
}

export default RightArrowSvg;
