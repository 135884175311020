/** @module CheersSvg */
import React, { PureComponent } from 'react';

class CheersSvg extends PureComponent {
  /** @inheritdoc */
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="35"
        height="30"
        viewBox="0 0 35 30"
      >
        <defs>
          <linearGradient id="a" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor="#FFB669" />
            <stop offset="100%" stopColor="#FD7F38" />
          </linearGradient>
        </defs>
        <g fill="url(#a)" fillRule="nonzero" transform="translate(-750 -2810)">
          <path
            fill="#FD7F38"
            d="M761.969 2811.76c-3.19-.855-6.727-1.16-6.727-1.16l-4.331 22.592s2.217 1.13 4.801 1.823c2.585.693 5.097.91 5.097.91l7.511-21.794s-3.161-1.517-6.351-2.372zm4.011 6.356l-10.663-2.858.695-3.62c.925.087 3.234.41 5.702 1.072 2.445.655 4.593 1.579 5.46 1.972l-1.194 3.434zM775.431 2815.687c-3.252.574-6.586 1.793-6.586 1.793l5.622 22.306s2.488.087 5.122-.377a33.468 33.468 0 0 0 5.004-1.33l-2.403-22.926s-3.507-.039-6.759.534zm6.322 4.066l-10.872 1.917-.9-3.575c.876-.312 3.105-.995 5.621-1.438 2.493-.44 4.83-.51 5.783-.52l.368 3.616z"
          />
        </g>
      </svg>
    );
  }
}

export default CheersSvg;
