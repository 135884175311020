import React, { PureComponent } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import classNames from 'classnames';
import RightArrowSvg from '../right-arrow-svg';
import tabitar from '../../assets/tabitar@2x.png';
import './sticky-header.scss';

class StickyHeader extends PureComponent {
  static emailIsValid(email) {
    const isEmpty = !email;
    const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return !isEmpty && emailRegex.test(email);
  }

  constructor() {
    super();
    this.state = {
      active: false,
      firstName: '',
      lastName: '',
      email: {
        value: '',
        showValidationError: false,
      },
      showBtn: true,
    };
  }

  handleEmailChange = event => {
    this.setState({
      email: {
        value: event.target.value,
        showValidationError: !StickyHeader.emailIsValid(event.target.value),
      },
    });
  };

  handleNameChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { firstName, lastName, email } = this.state;
    if (StickyHeader.emailIsValid(email.value)) {
      const result = await addToMailchimp(email.value, {
        FNAME: firstName,
        LNAME: lastName,
      });
      if (result.result === 'success') {
        // show success!
        this.setState({
          showBtn: false,
        });
        // after 2 seconds, clear and close
        setTimeout(() => {
          this.setState({
            active: false,
            firstName: '',
            lastName: '',
            email: {
              value: '',
              showValidationError: false,
            },
            showBtn: true,
          });
        }, 2000);
      }
    } else {
      this.setState({
        email: {
          value: email.value,
          showValidationError: true,
        },
      });
    }
  };

  toggle = () => {
    const { active } = this.state;
    this.setState({
      active: !active,
    });
  };

  /** @inheritdoc */
  render() {
    const { active, email, firstName, lastName, showBtn } = this.state;
    const formClass = classNames({
      'tio--mailchimp-form': true,
      active,
    });
    const validFormClass = classNames({
      'tio--input-container': true,
      'tio--input-border': true,
      'tio--rounded-left': true,
      'w-100': true,
      invalid: email.showValidationError,
      valid: !email.showValidationError,
    });
    const validEmailClass = classNames({
      'tio--text-input': true,
      'tio--rounded-left': true,
      'tio--input-validation-border': true,
      'tio--email-mailchimp-input': true,
      invalid: email.showValidationError,
      valid: !email.showValidationError,
    });
    const submitButtonClass = classNames({
      'tio--small-btn': true,
      'tio--rounded-right': true,
      'tio--body-text-1': true,
      'tio--right-arrow': true,
      'tio--purple-arrow': true,
      'tio--submit-mailchimp-btn': true,
      'd-none': !showBtn,
    });
    const successClass = classNames({
      'tio--body-text-1': true,
      'd-flex': !showBtn,
      'align-items-center': true,
      'tio--pl-1': true,
      'tio--pr-1': true,
      'justify-content-center': true,
      'd-none': showBtn,
    });
    return (
      <div className="tio--sticky-header-wrapper">
        <div className="tio--sticky-header w-100 tio--pt-1 tio--pb-1">
          <div className="tio--sticky-header-container">
            <div className="d-flex justify-content-center justify-content-md-between align-items-center">
              <div className="d-flex align-items-center">
                <img
                  src={tabitar}
                  alt="Tabitha avatar"
                  className="tio--tabitar tio--pr-2"
                />
                <div className="d-none d-md-block">
                  <span className="tio--body-text-1">
                    Get Tabitha for your business
                  </span>
                  <br />
                  <span className="tio--body-text-3">
                    Integrates with your POS
                  </span>
                </div>
              </div>
              <button
                type="button"
                className="tio--large-btn tio--sweep-btn tio--pl-4 tio--pr-4 tio--body-text-1"
                onClick={this.toggle}
              >
                Stay in the loop
              </button>
            </div>
            <form className={formClass} onSubmit={this.handleSubmit}>
              <input
                type="text"
                name="firstName"
                value={firstName}
                placeholder="First name"
                className="tio--text-input tio--input-border w-100"
                onChange={this.handleNameChange}
              />
              <input
                type="text"
                name="lastName"
                value={lastName}
                placeholder="Last name"
                className="tio--text-input tio--input-border tio--ml-2 tio--mr-2 w-100"
                onChange={this.handleNameChange}
              />
              <div className={validFormClass}>
                <input
                  type="email"
                  name="email"
                  value={email.value}
                  placeholder="Email address"
                  className={validEmailClass}
                  onChange={this.handleEmailChange}
                />
              </div>

              <button type="submit" className={submitButtonClass}>
                Send
                <RightArrowSvg />
              </button>
              <div className={successClass}>Success!</div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default StickyHeader;
