import React, { Fragment, PureComponent } from 'react';
import drink0 from '../assets/bar-drink-0.svg';
import drink1 from '../assets/bar-drink-1.svg';
import drink3 from '../assets/bar-drink-3.svg';
import blake from '../assets/blake-bw@2x.png';
import illo1 from '../assets/illo-welcome-1.svg';
import illo2 from '../assets/illo-welcome-2.svg';
import illo3 from '../assets/illo-welcome-3.svg';
import logo from '../assets/logo.svg';
import marissa from '../assets/marissa-bw@2x.png';
import rohit from '../assets/rohit-bw@2x.png';
import squiggle from '../assets/squiggle.svg';
import tabList from '../assets/tab-list.png';
import tabithaTextGrey from '../assets/tabitha-grey.svg';
import tabithaText from '../assets/tabitha-orangeyred.svg';
import tabithaPhoneHand from '../assets/tabitha-phone-hand.png';
import triangleOff from '../assets/triangle-off.png';
import triangleOn from '../assets/triangle-on.png';
import SEO from '../components/seo';
import StickyHeader from '../components/sticky-header/sticky-header';
import '../styles/index.scss';
import GlassesSvg from '../components/glasses-svg';
import CheersSvg from '../components/cheers-svg';

class App extends PureComponent {
  render() {
    return (
      <Fragment>
        <SEO
          title="Home"
          description="Pay your tab without the wait. Coming soon to a bar or restaurant near you."
        />
        <StickyHeader />
        <div className="App">
          <header className="tio--header align-items-start">
            <div className="tio--pattern-splash-top" />
            <div className="tio--tabitha-container w-100 row no-gutters">
              <div className="col-12 col-lg-6">
                <div className="tio--col-tightener">
                  <img
                    src={tabithaText}
                    alt="Tabitha"
                    className="tio--tabitha-text-img"
                  />
                  <h2 className="tio--font-20 tio--font-600 tio--text-greyish tio--pt-2 tio--pb-2">
                    Pay your tab without the wait.
                  </h2>
                  <img src={squiggle} alt="Squiggle" />
                </div>
              </div>
              <div className="col-12 col-lg-6 ">
                <div className="tio--col-tightener">
                  <img src={logo} alt="Tabitha logo" />
                </div>
              </div>
            </div>
          </header>
          <div className="row no-gutters">
            <div className="col-md-12 col-lg-6 tio--gradient-col tio--phone-hand-col">
              <div className="tio--drinks-container tio--col-tightener">
                <div className="tio--pb-5">
                  <img src={drink0} className="tio--drink-icon" alt="drink" />
                  <img src={drink1} className="tio--drink-icon" alt="drink" />
                  <img src={drink3} className="tio--drink-icon" alt="drink" />
                </div>
                <div className="tio--text-blackish tio--callout-text">
                  The easiest way to pay your bar or restaurant tab.
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 tio--bg-dark-grey tio--phone-hand-col">
              <img
                src={tabithaPhoneHand}
                alt="Tabitha Home"
                className="position-absolute tio--tabitha-phone-hand"
              />
            </div>
          </div>
          <div className="tio--how-it-works">
            <h2 className="tio--title tio--text-greyish text-center text-uppercase">
              How It Works
            </h2>
            <div className="row">
              <div className="col-sm-12 col-md-4 d-flex flex-column align-items-center justify-center tio--how-it-works-section">
                <img src={illo1} alt="phone" className="tio--scaling-img" />
                <div className="tio--text-greyish tio--body-text-2 text-center tio--pt-2 tio--pb-3">
                  Start a bar tab from your phone using the Tabitha app.
                </div>
                <div>
                  <img
                    src={triangleOn}
                    alt="Step 1"
                    className="tio--active-triangle"
                  />
                  <img
                    src={triangleOff}
                    alt="Step 2"
                    className="tio--pl-4 tio--pr-4"
                  />
                  <img src={triangleOff} alt="Step 3" />
                </div>
              </div>
              <div className="col-sm-12 col-md-4 d-flex flex-column align-items-center justify-center tio--how-it-works-section">
                <img src={illo2} alt="drink" className="tio--scaling-img" />
                <div className="tio--text-greyish tio--body-text-2 text-center tio--pt-2 tio--pb-3">
                  Order from the bartender and give your name as you normally
                  do.
                </div>
                <div>
                  <img src={triangleOff} alt="Step 1" />
                  <img
                    src={triangleOn}
                    alt="Step 2"
                    className="tio--pl-4 tio--pr-4 tio--active-triangle"
                  />
                  <img src={triangleOff} alt="Step 3" />
                </div>
              </div>
              <div className="col-sm-12 col-md-4 d-flex flex-column align-items-center justify-center tio--how-it-works-section">
                <img
                  src={illo3}
                  alt="drinks and phone"
                  className="tio--scaling-img"
                />
                <div className="tio--text-greyish tio--body-text-2 text-center tio--pt-2 tio--pb-3">
                  Pay your tab using Tabitha and never leave your card at the
                  bar again!
                </div>
                <div>
                  <img src={triangleOff} alt="Step 1" />
                  <img
                    src={triangleOff}
                    alt="Step 2"
                    className="tio--pl-4 tio--pr-4"
                  />
                  <img
                    src={triangleOn}
                    alt="Step 3"
                    className="tio--active-triangle"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="tio--convenience-control">
            <h2 className="tio--title text-center text-uppercase">
              Convenience and Control
            </h2>
            <div className="row no-gutters">
              <div className="col-md-12 col-lg-6">
                <div className="tio--col-tightener text-center text-lg-left">
                  <img src={tabList} alt="Tab" className="tio--tab-list" />
                </div>
              </div>
              <div className="col-md-12 col-lg-6 d-flex flex-column justify-content-around align-items-center">
                <div className="tio--col-tightener tio--col-right">
                  <div className="tio--font-24 tio--font-600 tio--control-item">
                    View and track your tab as you order.
                  </div>
                  <div className="tio--font-24 tio--font-600 tio--control-item">
                    Adjust the tip amount and credit card on file.
                  </div>
                  <div className="tio--font-24 tio--font-600 tio--control-item">
                    When you're done, settle up directly in the app.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <a
              href="mailto:hello@tabitha.io"
              target="_blank"
              rel="noopener noreferrer"
              className="col-sm-12 col-md-6 tio--bg-blackish d-flex flex-column align-items-center justify-content-center tio--pt-3 tio--pb-3 tio--sweep-cta tio--to-right tio--rainbow-to-right tio--no-underline"
            >
              <GlassesSvg />
              <div className="tio--font-20 tio--text-greyish tio--font-400 tio--pt-1">
                Get in touch
              </div>
            </a>
            <a
              href="mailto:support@tabitha.io"
              target="_blank"
              rel="noopener noreferrer"
              className="col-sm-12 col-md-6 tio--bg-dark-grey d-flex flex-column align-items-center justify-content-center tio--pt-3 tio--pb-3 tio--sweep-cta tio--to-left tio--rainbow-to-left tio--no-underline"
            >
              <CheersSvg />
              <div className="tio--font-20 tio--text-greyish tio--font-400 tio--pt-1">
                Reach out for support
              </div>
            </a>
          </div>
          <div className="tio--tabitha-footer tio--bg-black text-center position-relative">
            <div>
              <img src={tabithaTextGrey} alt="Tabitha" />
            </div>
            <div className="tio--pt-3 tio--pb-2">
              <img src={squiggle} alt="Squiggle" />
            </div>
            <div className="tio--font-300 tio--footer-tagline">
              Eat, drink, and pay easy
            </div>
            <div className="tio--text-greyish tio--font-20 tio--font-600 tio--made-with-love tio--pb-4">
              Made with love in DC/NoVa
            </div>
            <div className="tio--founders">
              <div className="row no-gutters">
                <div className="col-sm-12 col-md-4 tio--text-greyish tio--body-text-2 d-flex flex-column">
                  <img src={blake} alt="Blake" />
                  Blake,
                  <br />
                  Founder
                </div>
                <div className="col-sm-12 col-md-4 tio--text-greyish tio--body-text-2 d-flex flex-column">
                  <img src={marissa} alt="Marissa" />
                  Marissa,
                  <br />
                  Founder
                </div>
                <div className="col-sm-12 col-md-4 tio--text-greyish tio--body-text-2 d-flex flex-column">
                  <img src={rohit} alt="Rohit" />
                  Rohit,
                  <br />
                  Founder
                </div>
              </div>
            </div>
            <div className="tio--pattern-splash-bottom">
              <span className="tio--text-greyish tio--body-text-3 position-absolute tio--copyright">
                &#169; Copyright 2019 TABITHA
              </span>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default App;
